<script>
import PrintA4 from "@/components/print/defaults/PrintA4"

export default {
  components: {PrintA4},
  nome: 'RelatoriosDefaultPrint',
  props: {
    data: {required: true},
    orientation: {
      type: String,
      default: 'portrait'
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  mounted() {
  },
  activated() {
  },
  created() {
  },
  deactivated() {
  },
  watch: {},
  methods: {}
}
</script>

<template>
  <print-a4 :orientation="orientation" :is-loading="isLoading">
    <div v-if="data && data.template" v-html="data.template.template || data.template"></div>
  </print-a4>
</template>
